.section {
	display: flex;
	min-height: 90vh;
	justify-content: space-between;
	width: 100%;
	/* padding: 1rem 0 0 0; */
	align-items: center;
}
.section > div {
	width: 47%;
}
.aboutImg {
	width: 80%;
	position: relative;
	padding-top: 90%;
	border: 1px solid var(--sec-color);
}
.aboutImg::after {
	content: "";
	border: 1px solid var(--sec-color);
	position: absolute;
	top: 1rem;
	z-index: -1;
	left: -1rem;
	width: 100%;
	height: 100%;
}
.section img {
	width: 100%;
	height: 100%;
	margin-top: -1rem;
	margin-left: 1rem;
	position: absolute;
	top: 0;
	left: 0;
	background-color: black;
	object-fit: cover;
}
.section h2 {
	font-size: 1.6rem;
}
.left-wrap > * {
	margin: 1rem 0;
}
.left-wrap h3 {
	margin-bottom: 0.5rem;
	text-align: left;
}
.left-wrap ul {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-content: space-between;
	text-align: left;
	margin: 0 0.9rem;
	margin-bottom: 1rem;
}
.left-wrap ul li {
	margin: 0.5rem;
}
@media (max-width: 760px) {
	.section {
		display: block;
		flex-direction: column;
		justify-content: space-evenly;
	}
	.aboutImg::after {
		display: none;
	}
	.section .aboutImg {
		width: 90%;
		max-width: 350px;
		margin: 0 auto 3rem auto;
		min-height: 420px;
	}
	.section > div {
		width: 100%;
		margin: auto;
		text-align: center;
	}
	.section img {
		margin-top: -0.8rem;
		margin-left: 0.8rem;
	}
	.left-wrap * {
		margin: 1.2rem auto;
	}
}
@media (max-width: 850px) {
	.left-wrap ul {
		grid-template-columns: 1fr 1fr;
	}
}
