.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
  overflow-x: hidden;
  flex-wrap: wrap;
  margin-top: 3rem;
}
.form,
.container .left-wrap {
  width: 48%;
  margin-left: 0.5rem;
}
.container .left-wrap {
  padding-left: 1.5rem;
}
.container .memoji {
  display: inline;
  margin-right: 1.5rem;
}
.container h2 {
  position: relative;
}
.container h2::after,
.container h2::before {
  content: "";
  height: 20rem;
  width: 20rem;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: -0.7rem;
  background-color: rgba(163, 162, 162, 0.473);
}
.container h2::after {
  top: 100%;
  left: -1rem;
  background-color: transparent;
  border: 1px solid #a3a2a2;
}
.container p {
  /* text-align: center; */
  font-weight: 500;
}
.container .text-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
}

.flex-form-grp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.sm-grp {
  width: 100%;
}

input,
select,
textarea {
  width: 100%;
  height: 45px;
  margin-top: 1rem;
  border: none;
  border-bottom: 1px solid #a3a2a2;
  transition: 0.5s;
  /* border-radius: 8px; */
  padding: 0.5rem;
  background-color: transparent;
}
input::placeholder,
textarea::placeholder {
  color: #5a5a5a;
  transition: 0.4s;
}
input:focus,
textarea:focus {
  border-color: --after-color;
}
.flex-form-grp .form-group {
  width: 48%;
  margin: 0.9rem 0 0 0;
}
textarea {
  height: 100px;
  resize: none;
  padding: 0.8rem 0.6rem;
}
.container i:first-child {
  margin-left: 0;
}
.form .btn {
  width: 100%;
  margin: 2rem auto;
}

@media (max-width: 760px) {
  .flex-form-grp .form-group,
  .flex-form-grp input {
    width: 100%;
  }
  .container {
    margin-top: 0;
    /* padding-top: 3rem; */
  }
  .form,
  .container .left-wrap {
    width: 100%;
    padding: 0.5rem;
  }
  .container h2 {
    font-size: 1.2rem;
  }
  .container h2::after,
  .container h2::before {
    display: none;
  }
  .container .left-wrap > * {
    margin: 0.5rem auto;
  }
  .container .left-wrap {
    padding-left: 0;
    text-align: center;
    /* padding-top: 2.5rem; */
  }
  .container .memoji {
    display: none;
  }
}
