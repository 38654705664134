.cardSection {
  margin: 2rem auto;
  text-align: center;
  position: relative;
}
.cardSection h1 {
  margin: 2rem 0;
  font-weight: 600;
}
.card {
  width: 46%;
  display: inline-block;
  background: var(--card-color);
  border-radius: 5px;
  margin: 2rem 0;
  text-align: left;
  position: relative;
  box-shadow: 2px 2px 15px var(--box-shadow);
}
.card:nth-child(even) {
  top: 5rem;
  margin-left: auto;
}
.card .btn {
  /* border-radius: 6px; */
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 2rem;
  margin: 1.5rem 0;
  width: auto;
}
.card .btn i {
  color: inherit;
}
.card .github {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  font-size: 1.8rem;
  background: white;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.card .github i {
  color: black;
  margin-top: 1.5px;
}
.card .textWrap {
  padding: 0 1.5rem 1rem 1.5rem;
}
.card p {
  font-size: 0.9rem;
}
.card .img {
  width: 100%;
  padding-top: 70%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 5px 5px 0 0;
  transition: background-size 0.4s;
}
.card .img:hover {
  background-size: 120% 120%;
}
.stacks {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1.5rem;
  margin-bottom: 1rem;
}
.stacks span {
  background-color: var(--card-color);
  /* border: 1px solid var(--sec-color); */
  border-radius: 50%;
  width: 3rem;
  display: grid;
  place-items: center;
  height: 3rem;
  margin: 0 0.5rem;
}
.stacks i {
  font-size: 1.8rem;
  padding: 0.5rem;
}
.stacks img {
  width: 100%;
  height: 100%;
  padding: 0.6rem;
}
.cardsWrap {
  display: flex;
  justify-content: flex-start;
  /* align-items: center */
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}
.card:last-child:nth-child(even) {
  margin-bottom: 5rem;
}
@media (max-width: 760px) {
  .card:nth-child(even) {
    transform: translateY(0);
  }
  .card {
    width: 100%;
    max-width: 450px;
    /* height: 100%; */
    margin: 1rem auto;
  }
  .card:nth-child(even) {
    top: none;
    position: static;
  }
}
