.nav {
	width: 100%;
	position: sticky;
	top: 0;
	z-index: inherit;
	left: 0%;
	padding: 1.2rem;
	flex-wrap: wrap;
}

.nav.scrolled {
	backdrop-filter: blur(10px);
	background-color: rgba(var(--primary-color), 0.4);
	/* border-bottom: 1px solid var(--after-color); */
}

.nav.scrolled ul a {
	text-shadow: 0 0 1px black;
}

.logo {
	font-family: Pacifico, cursive;
	font-size: 1.4rem;
	font-weight: 400;
}
nav .inner,
nav .navList {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

nav .navList {
	list-style: none;
	width: auto;
}
.showNav {
	display: flex;
}
.navLink {
	margin: 0 1rem;
	font-size: 0.95rem;
}

ul a {
	padding: 0.5rem 0;
	color: var(--text-color);
	text-decoration: none;
	position: relative;
}

ul a::after {
	content: "";
	width: 100%;
	transform: scaleX(0);
	position: absolute;
	bottom: 0%;
	left: 0;
	background-color: var(--after-color);
	height: 3px;
	transform-origin: left;
	transition: transform 0.3s;
}
ul a:hover::after {
	transform: scaleX(1);
}

nav .fa-bars,
nav .fa-times,
.nav-sm button {
	display: none;
	cursor: pointer;
	font-size: large;
}

@media (max-width: 760px) {
	nav .fa-bars,
	nav .fa-times,
	.nav-sm button {
		display: block;
		font-size: 1.4rem;
	}
	nav .fa-times {
		position: absolute;
		top: 1.3rem;
		right: 1.3rem;
	}
	nav .navList {
		display: none;
	}
}

.nav-sm {
	position: fixed;
	width: 100vw;
	height: 100vh;
	display: flex;
	z-index: 1;
	justify-content: flex-end;
	align-items: center;
	flex-direction: row;
}

.nav-sm button {
	color: var(--primary-color);
	border-radius: 100%;
	border: none;
	width: 35px;
	height: 35px;
	outline: none;
	background: var(--after-color);
	position: absolute;
	top: 8.5%;
	right: 10%;
}

.nav-sm ul {
	width: 70%;
	height: auto;
	padding: 2rem;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	flex-direction: column;
}

.nav-sm a,
.nav-sm li {
	color: var(--text-color);
	font-size: 16px;
	margin: 0.5rem;
	text-decoration: none;
	list-style: none;
	display: inline-block;
}
/* 
.logo {
  padding-left: 0.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}

.logo img {
  margin: 0 0.5rem;
  width: 37px;
  height: 37px;
}

.logo:link,
.logo:visited,
.logo:hover,
.logo:active {
  color: var(--sec-color);
}

.logo-text {
  font-size: 1.7rem;
  font-weight: 400 !important;
  color: var(--sec-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */
.nav-sm a {
	padding: 0.5rem 0.5rem 0.2rem 0;
	margin: 0 1rem;
}

@media (min-width: 790px) {
	.logo img {
		width: 50px;
		height: 50px;
	}
	.logo-text {
		font-size: 2rem;
	}
}
