body {
	margin: 0;
	font-family: "Plus Jakarta Sans";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100vw;
	overflow-x: hidden;
}
textarea::placeholder,
input::placeholder {
	font-family: "Plus Jakarta Sans";
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	text-decoration: none;
	color: inherit;
}
p {
	line-height: 2rem !important;
}
.fa-html5 {
	color: #fa0606;
}
.fa-css3 {
	color: #008cff;
}
.fa-js {
	color: #ffe602;
}
.fa-sass {
	color: rgb(173, 3, 32);
}
.fa-react {
	color: #61dbfb;
}
p {
	line-height: 1.6;
}
